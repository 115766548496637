<template>
  <v-container fluid>
    <v-dialog v-model="dialog" max-width="800px">       
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <!-- <v-layout wrap> -->

            <v-row dense>
              <v-col cols="12">
                <v-card>
                  <v-card-title>Main Settings</v-card-title>

                  <v-card-text>
                    <v-layout wrap>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-text-field v-model="host.hostId" :label="$t('host.host_id')" disabled></v-text-field>
                      </v-flex>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-text-field v-model="host.email" :label="$t('host.email')" disabled></v-text-field>
                      </v-flex>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-select
                          v-model="selectedPms"
                          :items="pmsList"
                          label="PMS"
                          item-text="pmsType"
                          item-value="pmsType"
                          clearable
                        ></v-select>  
                      </v-flex>

                      <v-flex xs12 sm12 md12 lg12>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green lighten-1" text dark @click="save">{{ $t('host.btn_save') }}</v-btn>

                          <v-btn v-if="returnUrl" color="green lighten-1" text dark :href="returnUrl" target="_blank">{{ $t('host.btn_sync') }}</v-btn>

                        </v-card-actions>
                      </v-flex>
                    </v-layout>                    
                  </v-card-text>


                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card>
                  <v-card-title>Smartlock Settings 
<!--                     <v-checkbox
                      v-model="checkbox"
                    ></v-checkbox> -->
                  </v-card-title>

                  <v-card-text>
                    <v-layout wrap>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-text-field v-model="host.smartlockProperty.smartlockHost" :label="$t('host.smartlock_host')"></v-text-field>
                      </v-flex>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-text-field v-model="host.smartlockProperty.smartlockAuth" :label="$t('host.smartlock_auth')"></v-text-field>
                      </v-flex>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-text-field v-model="host.smartlockProperty.resourceServerIdentifier" :label="$t('host.resource_server_identifier')"></v-text-field>
                      </v-flex>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-switch
                          v-model="host.smartlockProperty.smartlockVerified"
                          :label="$t('host.verified')"
                          readonly
                        ></v-switch>
                      </v-flex>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-text-field v-model="host.smartlockProperty.clientId" :label="$t('host.client_id')" type="password"></v-text-field>
                      </v-flex>
                      <v-flex xs4 sm4 md4 lg4>
                        <v-text-field v-model="host.smartlockProperty.clientSecret" :label="$t('host.client_secret')" type="password"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green lighten-1" text dark @click="refresh">{{ $t('host.btn_refresh') }}</v-btn>
                        </v-card-actions>
                      </v-flex>
                    </v-layout>
                  </v-card-text>


                </v-card>
              </v-col>

<!--               <v-col cols="12">
                <v-card>
                  <v-card-title>Channel Settings</v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-data-table
                          v-model="selectedPms"
                          :items="pmsList"
                          item-key="pmsType"
                          :headers="headersPmsList"
                          class="elevation-1"
                          select-strategy="single"
                          show-select
                          hide-default-footer
                        >
                        </v-data-table>   
                      </v-flex>
                      <v-flex xs6 sm6 md6 lg6>
                        <v-card-actions>
                          <v-btn color="green lighten-1" text dark @click="verify">{{ $t('host.btn_verify') }}</v-btn>
                        </v-card-actions>
                      </v-flex>
                    </v-layout>
                    
                  </v-card-text>

                </v-card>
              </v-col>
 -->
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green lighten-1" text dark @click="close">{{ $t('host.btn_cancel') }}</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 28px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'  
import { CallDialog } from '@/utils'
import store from '@/store'

export default {
  data: () => ({
    dialog: true,
    selectedPms: ''
  }),

  computed: {
    formTitle () {
      return this.$t('host.btn_edit')
    },
    headersPmsList() {
      return [
        { text: this.$t('devices.table_col_username'), value: 'pmsType' },
        { text: this.$t('devices.table_col_device_ids'), value: 'verified', sortable: false },
      ]
    },
    ...mapState({
      host: state => state.host.host,
      devices: state => state.host.devices,
      defaultSecret: state => state.host.defaultSecret,
      pmsList: state => state.host.pmsList,
      hostPms: state => state.host.hostPms,
      returnUrl: state => state.host.returnUrl,
    }),
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  async beforeRouteEnter (to, from, next) {
    
    store.commit('host/setReturnUrl', null)

    const error = await store.dispatch('host/loadHost').catch(error => {
      return error
    })

    if (error) {
      CallDialog(error)
    }

    const error2 = await store.dispatch('host/getPmsList').catch(error => {
      return error
    })
    
    if (error2) {
      CallDialog(error2)
    }

    const error3 = await store.dispatch('host/getHostPms').catch(error => {
      return error
    })

    if (error3) {
      CallDialog(error3)
    } else {
      next(vm => {
        vm.selectedPms = store.state.host.hostPms?.pmsType
      })
    }

  },
  methods: {
    close () {
      this.gotoListings()
    },
    async verify () {
      const error = await this.verifyChannel({
        inviteCode: this.inviteCode,
        hostId: this.host.hostId
      }).catch(e => {
        console.error('verifyChannel', e)
        return e
      })

      if (error) {
        CallDialog(error)  
      }
    },
    async save () {
      const error = await this.updateHost(this.host).catch(e => {
        console.error('updateHost', e)
        return e
      })

      if (error) {
        CallDialog(error)  
      }

      const error2 = await this.updateHostPms([{
        hostId: this.host.hostId,
        pmsType: this.selectedPms
      }]).catch(e => {
        console.error('updateHostPms', e)
        return e
      })

      if (error2) {
        CallDialog(error2)
      }

      if (this.selectedPms) {
        const error3 = await this.initHostPms(this.selectedPms).catch(error => {
          console.error('initHostPms', error)
          return error
        })

        if (error3) {
          CallDialog(error3)
        }        
      }
    },
    async refresh () {
      const error = await this.refreshDevices({
        hostId: this.host.hostId,
        smartlockHost: this.host.smartlockProperty.smartlockHost,
        smartlockAuth: this.host.smartlockProperty.smartlockAuth,
        resourceServerIdentifier: this.host.smartlockProperty.resourceServerIdentifier,
        clientId: (this.host.smartlockProperty.clientId === this.defaultSecret ? null : this.host.smartlockProperty.clientId),
        clientSecret: (this.host.smartlockProperty.clientSecret === this.defaultSecret ? null : this.host.smartlockProperty.clientSecret)
      }).catch(e => {
        console.error('refreshDevices', e)
        return e
      })

      if (error) {
        CallDialog(error)  
      }
    },
    // async importPms () {
    //   const error = await this.importListings(this.hostPms).catch(e => {
    //     console.error('importPms', e)
    //     return e
    //   })

    //   if (error) {
    //     CallDialog(error)  
    //   }
    // },
    ...mapActions({
      loadListings: 'host/loadHost',
      gotoListings: 'host/gotoListings',
      updateHost: 'host/updateHost',
      refreshDevices: 'host/refreshDevices',
      verifyChannel: 'host/verifyChannel',
      initHostPms: 'host/initHostPms',
      updateHostPms: 'host/updateHostPms',
      // importListings: 'host/importListings'
    })
  }
}
</script>